export function useCleanCache() {
  const payPalPendingPaymentCookie = useCookie('paypal_pending_payment')

  function cleanInfSignup() {
    if (import.meta.client) {
      window.localStorage.removeItem('authCreateUserBackup')
      window.localStorage.removeItem('createBaseInfluencerBackup')
      window.localStorage.removeItem('patchLinkInfluencerBackup')
      window.localStorage.removeItem('patchSubsAndGenreBackup')
    }
  }

  function cleanBandSignup() {
    if (import.meta.client) window.localStorage.removeItem('flowSignupBackUp')
  }

  function cleanPaypalAuthBackup() {
    payPalPendingPaymentCookie.value = null
  }

  function cleanCacheAll() {
    cleanBandSignup()
    cleanInfSignup()
    cleanPaypalAuthBackup()
  }

  return {
    cleanInfSignup,
    cleanBandSignup,
    cleanPaypalAuthBackup,
    cleanCacheAll,
  }
}
